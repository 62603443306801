import React from "react"

const Video = ({ src, className }) => {

  return (
    <video className={className} playsInline autoPlay muted loop style={{width: '100%'}} key={src}>
      <source src={src} />
    </video>
  )
}

export default Video
