import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { Link } from 'gatsby';
import { breakpoints } from '~styles/global'

const Button = ({ label, active, children, to }) => {

  const {mobile} = breakpoints

  return (
    <Link
      css={css`
        background-color: transparent;
        border-radius: 100px;
        padding: 1.3em 3em 1em;
        border: 1px solid var(--black);
        &.active {
          background-color: var(--white);
        }
      `}
      activeClassName="active"
      to={to}
    >
      {children}
    </Link>
  )
}

Button.propTypes = {
  active: PropTypes.bool,
  label: PropTypes.string.isRequired,
}

Button.defaultProps = {
  active: false,
  label: 'Button',
}

export default Button
