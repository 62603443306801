import React from "react"
import { css } from '@emotion/react';
import Layout from "~components/Layout"
import Seo from "~components/Seo"
import { breakpoints } from '~styles/global'
import RenderBlock from "~components/RenderBlock"
import ButtonMinor from "~components/ButtonMinor"

const Page = ({ pageContext }) => {
  const { id, title, slug, content } = pageContext
  const {mobile} = breakpoints

  return (
    <>
      <Seo
        title={title}
        image={content.seo?.socialImage?.asset?.url}
        description={content.seo?.metaDescription}
      />
			{['about', 'delivery', 'faq'].includes(slug) ?
				<div css={css`
					display: grid;
					grid-template-columns: repeat(3, min-content);
					justify-content: center;
					grid-gap: 2rem;
					margin-top: 2rem;
					margin-bottom: 12rem;
					${mobile} {
						display: none;
					}
				`}>
					<ButtonMinor to={"/about"}>About</ButtonMinor>
					<ButtonMinor to={"/delivery"}>Delivery</ButtonMinor>
					<ButtonMinor to={"/faq"}>FAQ</ButtonMinor>
				</div> :
				<div css={css`height: 8rem`}/>
			}
      {content?.Blocks?.map((block, i) => (
        <RenderBlock 
          content={block} 
          key={block?._key} 
          type={block?._type} 
          lastBlock={content?.Blocks?.length - 1 === i}
          css={css`
            &:first-of-type{
              ${mobile}{
                margin-top: 4rem;
              }
            }
        `}/>
      ))}
    </>
  )
}

export default Page

